import React, { useRef, useState, useEffect } from 'react';
import SilentErrorBoundary from '../SilentErrorBoundary';

const VideoPlayer = ({ isVisible, onClose }) => {

    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(true);
    const [volume, setVolume] = useState(0.5);
    const [progress, setProgress] = useState(0); // Fortschritt des Videos in %


    const togglePlayPause = () => {
        try{
            if (isPlaying) {
                videoRef.current.pause();
            } else {
                videoRef.current.play();
            }
        } catch {}
        setIsPlaying(!isPlaying);
    };

    const handleVolumeChange = (e) => {
        try{
            const newVolume = e.target.value;
            setVolume(newVolume);
            videoRef.current.volume = newVolume;
        } catch {}   
    };

    const handleSeek = (e) => {
        try{
            const newTime = (e.target.value / 100) * videoRef.current.duration;
            videoRef.current.currentTime = newTime;
            setProgress(e.target.value);
        } catch {} 
    };

    const updateProgress = () => {
        try{
            const currentTime = videoRef.current.currentTime;
            const duration = videoRef.current.duration;
            setProgress((currentTime / duration) * 100);
        } catch {} 
    };

    useEffect(() => {
        try{
            videoRef.current.play()

            if (videoRef.current) {
                videoRef.current.addEventListener('timeupdate', updateProgress);
            }
            return () => {
                if (videoRef.current) {
                    videoRef.current.removeEventListener('timeupdate', updateProgress);
                }
            };
        } catch {} 
    }, []);

    if (!isVisible) return null;

    try {
    return (
        <SilentErrorBoundary>
            <div className="fullscreen-video-wrapper">
                <div className="video-overlay" />
                <div className="video-controls">
                    <div className="pause-play-wrapper">
                        <button onClick={togglePlayPause}>
                            {isPlaying ? <div className="pause-symbol"></div> : <div className="triangle-right"></div>}
                        </button>
                    </div>

                    <input
                        type="range"
                        className="timeline-slider"
                        min="0"
                        max="100"
                        step="1"
                        value={progress}
                        onChange={handleSeek}
                        style={{
                            background: `linear-gradient(to right, #0B998B ${progress}%, #333 ${progress}%)`,
                        }}
                    />

                    <input
                        type="range"
                        className="volume-slider"
                        min="0"
                        max="1"
                        step="0.1"
                        value={volume}
                        onChange={handleVolumeChange}
                    />

                    <button className="video-close-button" onClick={onClose}>
                        X
                    </button>
                </div>
                <video
                    ref={videoRef}
                    className="fullscreen-video"
                    src="/vids/How-To Buy BEME On ICO_mute_compressed.mp4"
                    onClick={togglePlayPause}
                />
            </div>
        </SilentErrorBoundary>

    );
} catch {}
};

export default VideoPlayer;
