import ErrorBoundary from '../ErrorBoundary'; 
import React, { useState } from 'react';
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
interface HelpWindowProps {
  onClose: () => void;
}
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
const HelpWindow: React.FC<HelpWindowProps> = ({ onClose }) => {

  const [openIndex, setOpenIndex] = useState<number | null>(null);

  const helpData = [
    { question: "I can't connect my wallet to the app.", 
        answer: `Not all wallets run the same technical standards. Depending on your device, operating System, browser and software versions, there may be differences in compatibility. 
        
        We found that Solflare wallet works most seamlessly in almost all technical environments.` },

        { question: "Why you don't support Phantom Wallet ?", 
          answer: `The process to be reviewed and approved by a third provider that Phantom works with is quite lengthy and in our view also inapproriate.
          
          The process is not based on code quality or KYC, but on a history of references, which makes it hard to impossible for new projects to integrate Phantom.
          
          However this doesn't stop us from doing what we do. We are a small team and focus our energy to making progress in a fast market. That's why we have quickly integrated almost all other Solana compatible wallets :-)` },

  

  
    { question: "I have another problem and need support.", 
      answer: "You can also reach out to one of the admins in our Telegram Community: https://t.me/beatsofmeow." },

];

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
  const toggleAnswer = (index: number) => {
    try{
      setOpenIndex(openIndex === index ? null : index);
  } catch(e){}
  };
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
  try{
      return (
        <ErrorBoundary>
          <div className="help-wrapper">
            <div className="faq-close-button" onClick={onClose}>
              <span className="faq-close-button-span">X</span>
            </div>
            <div className="faq-header">
              <h2 className="faq-title">Help</h2>
                  <hr></hr>
              <div className="faq-content">
                {helpData.map((item, index) => (
                  <div key={index}>
                    <div className="faq-toggle-and-question-div" onClick={() => toggleAnswer(index)}>
                      <span className="faq-question-toggle">{openIndex === index ? '-' : '+'} </span>
                      <span className="faq-question">{item.question}</span>
                    </div>
                    {openIndex === index && (
                      <div>
                        <span className="faq-answer"><div>
                    {item.answer.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                  </div></span>
                      </div>
                    )}
                    <hr></hr>
                    
                  </div>
                ))}
              </div>
            </div>
          </div>
        </ErrorBoundary>
      );
  } catch(e){}
};
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
export default HelpWindow;
