  export const moremeows = [
    { name: "meow (1)", file: "/meows/meow (1).mp3" },
    { name: "meow (2)", file: "/meows/meow (2).mp3" },
    { name: "meow (3)", file: "/meows/meow (3).mp3" },
    { name: "meow (4)", file: "/meows/meow (4).mp3" },
    { name: "meow (5)", file: "/meows/meow (5).mp3" },
    { name: "meow (6)", file: "/meows/meow (6).mp3" },
    { name: "meow (7)", file: "/meows/meow (7).mp3" },
    { name: "meow (8)", file: "/meows/meow (8).mp3" },
    { name: "meow (9)", file: "/meows/meow (9).mp3" },
    { name: "meow (10)", file: "/meows/meow (10).mp3" },
    { name: "meow (11)", file: "/meows/meow (11).mp3" },
    { name: "meow (12)", file: "/meows/meow (12).mp3" },
    { name: "meow (13)", file: "/meows/meow (13).mp3" },
    { name: "meow (14)", file: "/meows/meow (14).mp3" },
    { name: "meow (15)", file: "/meows/meow (15).mp3" },
    { name: "meow (16)", file: "/meows/meow (16).mp3" },
    { name: "meow (17)", file: "/meows/meow (17).mp3" },
    { name: "meow (18)", file: "/meows/meow (18).mp3" },
    { name: "meow (19)", file: "/meows/meow (19).mp3" },
    { name: "meow (20)", file: "/meows/meow (20).mp3" },
    { name: "meow (21)", file: "/meows/meow (21).mp3" },
  ];