export const meows = [
    { name: "meow (1)", file: "/meows/meow (1).mp3" },
    { name: "meow (2)", file: "/meows/meow (2).mp3" },
    { name: "meow (3)", file: "/meows/meow (3).mp3" },
    { name: "meow (4)", file: "/meows/meow (4).mp3" },
    { name: "meow (5)", file: "/meows/meow (5).mp3" },
    { name: "meow (6)", file: "/meows/meow (6).mp3" },
    { name: "meow (7)", file: "/meows/meow (7).mp3" },
    { name: "meow (8)", file: "/meows/meow (8).mp3" },
    { name: "meow (9)", file: "/meows/meow (9).mp3" },
    { name: "meow (10)", file: "/meows/meow (10).mp3" },
    { name: "meow (11)", file: "/meows/meow (11).mp3" },
    { name: "meow (12)", file: "/meows/meow (12).mp3" },
  ];