
export const stage1 = [
    "7PEUhAXQA5QUYqepscydSQ6QLrMPxt2Fk7ByDduuoNKT", // for testing
    "LEX1tqVVh3A8tfh1brR2yVQqdse8UKEAR6JG3zkhyzZ",
    "a2FCepWdYgMmHPJKdsKyKEvg7ukHFLZGHnCKEijjANx",
    "FMeZopGgR6bLdVC9rxN6q2p8FDoaqcNkqtQQJpTFuqJV",
    "TvQUgsQ9d1PA4G7dLmLJdDgRXpuR1AXftoFcADA42Cg",
    "6gv99YhRLiFQBj2cHx67J5qgMi5yvcohPJmG2aaF2Lfm",
    "79rUArnwrFJ1LxaYeDmxByxd5hvHDNP5r3WMWF1aHQ5Q",
    "Gch3fP2K3K3aN6RVGWCkzo46xpSRSRhbMZHix54yTz6D",
    "FCyVVv52gsiRionXeoqC6PwkwwruiKmXUUejUFqeoBMT",
    "226Mhw5FghZ6p7TgG3QiASBtcPiGQLuUEGYd3Hpdq1Du",
    "GQ6bAwWTgUqwn2EGrHEpyBppCNs2atCk4MLoAdWgmVXH",
    "4S86s8EwkkHqfXRoRbvXPC5xjbHDaQmSgn7aCcxDfhNc",
    "ct8tWoLbGyHTUSWujQWRuQXHNzV8A9PcCsFGi5VmtPq",
    "9uutnJdRvuSnru7BYcXYydJpEYF6kHWrSn4sCHNyA9zu",
    "2ar9ujbd7upFyHaaAmTKGspCHcg9qbvyXu6qezyufmRT",
    "APTtp4qUB98KEsyeWkMxGQQwMVJTeUMKtB1F2ML8LFy8",
    "8jBfXUD2P91ybZLh5pYKfQDP1ZcjzwojTLJTbaUqGzBL",
    "HQZi8ye9atGG5x5cz8Ad2B2QpgAM9Q7cQhuGEFgRk9EZ",
    "871Veny8JKJ2CmvDiLvoQY9EMWeBuUhtryqmLPM8E1B3",
    "VjvMaX3PrjSXnF9f76DKSWwvo4EDTpRoEDCkEAnr9ne",
    "2wMUkHBZgycJgvQ3gq3akM1UdtrxKSBAQcjL94dRAbSP",
    "BPBRivgUJSUH6w76zB5PzEdwzSbs36M54r1CCswadCEf",
    "5B1FHJzE2CAB4c9mX5hythofLVmZ6quvLVRGcoXU5aF3",
    "9E2aNjxzr5oi7N7hfNossojoPqY9J79ppXb71dD6nA9v",
    "Bj1YADxDVbh61ae93ueH2PW86xJVd8SaVdjZS13eQtRL",
    "3qd5zbbbtMcmD9gDcF85GgjzZ7huzNeksR3VCYbe7feC",
    "64VMAAo1HPitaFT2A8N79Q84cX8GNbuqZHwwpVcSpWWN",
    "9Hqzx6rSyZ8q68Apqf23uwkPaZhySYHsG3ENeb5xyVc6",
    "8nu6FMEHPv8sshTX3rJs4MMTjvSfTmVpSdVUGtW9XSyJ",
    "EGK5YPkLTEfeb8BdxN9zKU7yUip8L9q3dE5GEQiiUJuW",
    "kwbobqHw69upuQTRSuqhFwtvqDzb2w5WiJHkpT87LhW",
    "48PmmrVfsEQR316LRrjtZRzBKStGxZzZKENSeAYoo5Us",
    "AngUPv4LtajAMEZicLDSPpm76GX7jXQNyL7rsgPR3opM",
    "22ZsBHcWwneb1PDFXALABqrG5dc5vSwRFFzKp4u6spxB",
    "9e8H7RCX4ZVmXLZMsSqrW21XzA7c66Hn6Q2DGFzEMQRD",
    "FZTfshnHitthzGvJq5NpcZLPpUVVtiGK9pxCFiz4FSPK",
    "Ar9R5To7mXYaY9XekNHyF18KuSHLCn5N2YyaLetnxyoE",
    "77XF13jr9SwgxwkNYGBRe3fsgDpyfJmJLMh2H9Hrmo17",
    "7e9aQJNnK98Rn8fcEJB99qF3ZoFquL94YtWg6v7pRKa",
    "DEVbPUWPGtQ1JMAsAGjpp2NWbvGKP8H4MXhnikFicsFs",
    "HtzZZDULzqdk6eV9jZZ8fG2L68L4oU4GMs8MAPUH68nP",
    "382zvvDVUi4yPXPVpKZd1FEKmcXjZApNSho1YvEvbwih",
    "7Ft3UG6vgxHL4inoEwEjfYgguy5Eq3tWMeWoqtsLSbY4",
    "HsJTJVYXQe91j6Q9B6whmiBBynvvjSuDAAoQka52tFk7",
];

export const stage2 = [
    "D1VknhTp26caoq5fvvkou6U9qccySRR49jFLPSavxdTi",
    "HaDZkteuCyTf8zZ7uz1JDeLL1YhyokrWVzkjzAQB6SXC",
    "DZ7e4dSKDVT1duQUPZfZ4eiuYoFL2o8ZDTN4937UsJiJ",
    "5myLnsT7hsdTsAVVmaZmKVA69cPaExGjkqXD3SyUSv78",
    "HzDQnVoJ5CDZ8dm99HhP9zM3VuZPGPuEwVdnGowQvbZv",
    "6oCSj9zZYS95NofPs2r8MvCu9NfL9qrVdqoo6Hys57Yg",
    "5Ry7tMYDzKG4zkM6XWit7sGiMWg2FnpwKDSq7rbAJv8U",
    "7ebCV1HVsNvuvNfn1h1tdZyxU64CJKBvmL2cKHfQFgin",
    "Zxc3hLJuuZzVwViFZ4okVmL2wBSnbJgXQLkjsGCUM75",
    "DJBoXWLZDZqK6kGs7oroL8giZSqmZNFzhSPHMEZnL4uz",
    "FTLspNbmyNzwECu36A8uSjBXPYLLg2aoLXCiXoSNDwuM",
    "3hgdqdCDKdjjyrNVWKT79kev8pvsmQkW8bg6zjckHuQ5",
    "DEV4gDDeV3Lf5RHiQ9om8XaYDadpuZHXBqjeRmBPettE"
];
