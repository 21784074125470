import SilentErrorBoundary from '../SilentErrorBoundary'; 
import React, { useState, useEffect } from 'react';
import './CatExplosion.css';
import {moremeows} from '../utils/moremeows';
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
const CatExplosionDesktop = ({catmode }) => {
    // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    const emojis = ["🐱", "😸", "😻", "🐈", "😽", "😼",];
    if(catmode){
      emojis.push("😹");
    }
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
  const [cats, setCats] = useState([])
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
    useEffect(() => {
      try{
        const updateMousePosition = (event) => {
            setMousePosition({ x: event.clientX, y: event.clientY });
        };
        window.addEventListener('mousemove', updateMousePosition);
        return () => window.removeEventListener('mousemove', updateMousePosition);
      } catch(e){}
    }, []);
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    useEffect(() => {
      try{
        const triggerFiesta = () => {
            if (catmode) {
                const button = document.getElementsByClassName('cat-explosion-button')[0];
                if (button) {
                    button.click();
                }
            // - - - - - -
            async function playMeows() {

              const randomIndex = Math.floor(Math.random() * moremeows.length);
              const randomMeow = moremeows[randomIndex]
              const audio = new Audio(randomMeow.file);
              audio.volume = 0.4; 
              audio.play();
              await new Promise(resolve => setTimeout(resolve, 100));
              

              /* // alternative audio playing
              // - - - - - -
              const randomIndex = Math.floor(Math.random() * moremeows.length);
              const randomMeow = moremeows[randomIndex];
              // - - - - - -
              const audioContext = new (window.AudioContext || window.webkitAudioContext)();
              const source = audioContext.createBufferSource();
              const analyser = audioContext.createAnalyser();
              const gainNode = audioContext.createGain();
              // - - - - - -
              const response = await fetch(randomMeow.file);
              const audioData = await response.arrayBuffer();
              const decodedData = await audioContext.decodeAudioData(audioData);
              // - - - - - -
              analyser.fftSize = 128;
              source.buffer = decodedData;
              gainNode.gain.setValueAtTime(0.4, audioContext.currentTime);
              // - - - - - -
              source.connect(analyser);
              analyser.connect(gainNode);
              gainNode.connect(audioContext.destination);
              // - - - - - -
              source.start();
            */



            }
            playMeows();
            // - - - - - -
          }
        };
        window.addEventListener('mouseup', triggerFiesta);
        return () => window.removeEventListener('mouseup', triggerFiesta);
      } catch(e){}
    }, [catmode]);
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
    useEffect(() => {
        try{
          const catduration = catmode? 4000 : 10000;
            const timer = setTimeout(() => {
              setCats([]);
            }, catduration);
            return () => clearTimeout(timer);
        } catch(e){}
    }, [cats]);
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
  const fiesta = () => {
    try{
        // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
        const catloop = catmode? 30 : 300;
        const catwait = catmode? 0.5 : 3;
        for (let i = 0; i < catloop; i++) {
          setTimeout(() => {
            let newCat;
                newCat = {
                    id: Math.random(),
                    emoji: emojis[Math.floor(Math.random() * emojis.length)],
                    startX: catmode? mousePosition.x : 800,
                    startY: catmode? mousePosition.y :450,
                    moveX: (Math.random() - 0.5) * 500 + (i -(0.994*i)),
                    moveY: (Math.random() - 0.5) * 800 + (i -(0.9967*i)),
                    rotation: Math.random() * 360 , 
                    duration: Math.random() * 0.8 + 1, 
                };
            setCats(prevCats => [...prevCats, newCat]);
          }, i * catwait); 
        }
      } catch(e){}
  };
  // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
  try{
      return (
        <SilentErrorBoundary>
          <div className="explosion-wrapper" style={{zIndex: catmode? 99999 : -99999}}>
            <button className="cat-explosion-button" onClick={fiesta}>
              DESKTOP 😸
            </button>
            {cats.map(cat => (
              <span 
                key={cat.id}
                className="emoji"
                style={{
                  transform: `{scale(${0.85})}`,
                  left: `${cat.startX}px`,
                  top: `${cat.startY}px`,
                  transform: `rotate(${cat.rotation   }deg)`,
                  animation: `fly ${cat.duration}s ease-out forwards`,
                  '--moveX': `${cat.moveX * 2.5 * Math.LOG2E }px`,
                  '--moveY': `${cat.moveY * 2 * Math.LOG2E}px`,
                  zIndex: catmode? 112010 : -99999,
                }}
              >
                {cat.emoji}
              </span>
            ))}
          </div>
          </SilentErrorBoundary>
      );
  } catch(e){}
};
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - 
export default CatExplosionDesktop;
